<template>
	<header class="header">
		<div class="header__wrapper">
			<Logo />
			<svg @click="open()" class="icon-button" viewBox="0 0 24 24">
				<rect x="1" y="4" width="22" height="1" rx=".5"></rect>
				<rect x="5" y="11" width="18" height="1" rx=".5"></rect>
				<rect x="3" y="18" width="20" height="1" rx=".5"></rect>
			</svg>
		</div>
		<span class="header__overlay" :class="{ 'opened': opened }"></span>
	</header>
</template>

<script>
import Logo from '@/components/Logo'
import eventHub from '@/services/eventHub'

export default {
	name: 'Sidebar',
	components: {
		Logo
	},
	data() {
		return {
			opened: false
		}
	},
	methods: {
		open() {
			this.opened = true
			eventHub.$emit('open-menu')
		}
	},
	mounted() {
		eventHub.$on('close-menu', () => {
			this.opened = false
		})
	}
}
</script>
