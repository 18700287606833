<template>
	<div>
		<Toolbar />
		<div class="is-flex">
			<!-- <b-loading :is-full-page="true" v-model="isLoading"></b-loading> -->
			<Sidebar />
			<main class="page">
				<slot v-if="permissions"></slot>
			</main>
		</div>
	</div>
</template>

<script>
import Toolbar from '@/components/Toolbar'
import Sidebar from '@/components/Sidebar'
import { mapState } from 'vuex'

export default {
	name: 'Layout',
	components: {
		Toolbar,
		Sidebar
	},
	computed: {
		...mapState('user', ['permissions'])
	}
}
</script>
